import React, { FC } from "react";
import { useLocalStorage } from "../hooks";
import logo from "../logo.png";

const Header: FC = () => {
  const [_, setLoggedIn] = useLocalStorage("loggedIn", false);
  return (
    <div className="h-24 flex items-center justify-between">
      <img src={logo} alt="logo" width={130} />
      <button onClick={() => setLoggedIn(false)} className="ml-1">
        logout
      </button>
    </div>
  );
};

export default Header;
