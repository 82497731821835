import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
} from "react-query";
import axios from "axios";
import { FEEDBACK, MET, URL } from "./constants";
import { FeedbackType } from "./types";
import { useDates } from "./useDates";
import moment from "moment";

export const getFeedbacks = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: FEEDBACK,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]}`,
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useFeedbacks(options?: QueryObserverOptions<FeedbackType[]>) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<FeedbackType[]>({
    queryKey: ["tracker-all-feedbacks", from, to],
    queryFn: getFeedbacks,
    ...options,
  });
}
