import React, { FC } from "react";
import cn from "classnames";

interface Props {
  variant: "primary" | "secondary";
  label: string;
  result: number | string;
}
const Box: FC<Props> = ({ label, result, variant }) => {
  const rootClassName = cn(
    "w-44 h-44 flex rounded-md shadow-md border border-gray-200 p-2 mr-2 mb-2",
    {
      "bg-gray-100": variant === "primary",
      "bg-white": variant === "secondary",
    }
  );
  return (
    <li className={rootClassName}>
      <div className="flex-1 flex flex-col justify-center items-center">
        <h4 className="text-center font-light">{label}</h4>
        <h2 className="font-extrabold">{result}</h2>
      </div>
    </li>
  );
};

export default Box;
