import React, { FC } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useDates } from "../hooks";
import moment from "moment";
moment.updateLocale(moment.locale(), { invalidDate: "ungültiger Tag" });

const DatePicker: FC = () => {
  const { dates, setDates } = useDates();
  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, dates);
    setDates(range);
  };

  const modifiersStyles = {
    selected: {
      backgroundColor: "#E5E7EB",
      color: "black",
      borderRadius: "0.375rem",
    },
    today: {
      color: "#FCA5A5",
    },
  };

  const onResetAll = () => {
    setDates({
      from: new Date("2020-04-30"),
      to: new Date(),
    });
  };

  const onResetDate = () => {
    setDates({
      from: dates.from,
      to: dates.to,
    });
  };

  const from = moment(dates.from).format("DD-MM-YYYY");
  const to = moment(dates.to).format("DD-MM-YYYY");

  return (
    <div className="flex flex-col pb-4 pr-2">
      <div className="flex">
        <DayPicker
          numberOfMonths={1}
          selectedDays={[dates.from, { from: dates.from, to: dates.to }]}
          modifiers={{ start: dates.from, end: dates.to }}
          modifiersStyles={modifiersStyles}
          onDayClick={handleDayClick}
        />
      </div>
      <p className="md:text-center">
        vom
        <span className="font-bold px-1">{from}</span>
        zum
        <span className="font-bold px-1">{to}</span>
      </p>
      <div>
        <button onClick={onResetAll} className="ml-1">
          alle Daten
        </button>
        <button onClick={onResetDate} className="ml-1">
          Auswahl aktualisieren
        </button>
      </div>
    </div>
  );
};

export default DatePicker;
