import React, {
  FC,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

type DatesProps = {
  from: Date;
  to: Date;
};

type ContextProps = {
  dates: DatesProps;
  setDates: Dispatch<SetStateAction<DatesProps>>;
};

export const DatesContext = createContext<ContextProps>({
  dates: {
    from: new Date(),
    to: new Date(),
  },
  setDates: () => undefined,
});

export const DatesProvider: FC = ({ children }) => {
  const [dates, setDates] = useState({ from: new Date(), to: new Date() });
  return (
    <DatesContext.Provider value={{ dates, setDates }}>
      {children}
    </DatesContext.Provider>
  );
};
