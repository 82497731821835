import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
} from "react-query";
import axios from "axios";
import { MET, TRACKER, URL } from "./constants";
import { useDates } from "./useDates";
import moment from "moment";
import { ModeType } from "./types";

export const getAllDistinctModes = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: TRACKER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]} and MODE#""`,
    distinct: "MODE",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useAllDistinctModes(
  options?: QueryObserverOptions<ModeType[]>
) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<ModeType[]>({
    queryKey: ["tracker-all-distinct-modes", from, to],
    queryFn: getAllDistinctModes,
    ...options,
  });
}

export const getCountMode = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: TRACKER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]} and MODE=${context.queryKey[3]}`,
    count: "MODE",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useCountMode(
  mode: string,
  options?: QueryObserverOptions<number>
) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<number>({
    queryKey: ["tracker-count-mode", from, to, mode],
    queryFn: getCountMode,
    ...options,
  });
}
