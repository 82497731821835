import React, { FC, SyntheticEvent, useState } from "react";
import { useDisabledUsers, useUserMutation } from "../hooks";

const Disabled: FC = () => {
  const [error, setError] = useState(false);
  const { data: disabledUsers, refetch } = useDisabledUsers();
  const { mutate } = useUserMutation({
    onSuccess: () => {
      refetch();
      setError(false);
    },
    onError: () => {
      setError(true);
    },
  });

  const onSubmit = (event: SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    // @ts-expect-error
    const id = event.target.username.value;
    mutate({
      ID: id,
      DISABLE_TRACKER: disabledUsers?.find((name) => name === id)
        ? false
        : true,
    });
  };

  return (
    <div>
      <form className="pb-2" onSubmit={onSubmit}>
        <input
          type="text"
          name="username"
          className={`${error ? "border-red-400" : "border-gray-200"}`}
        />
        <button type="submit" className="ml-1">
          senden
        </button>
      </form>
      <div className="flex flex-wrap items-center">
        {disabledUsers?.map((user, idx) => (
          <p
            className={`px-2 py-1 border border-gray-200 rounded-md shadow-md mr-2 ${
              idx % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
          >
            {user.ID}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Disabled;
