import moment from "moment";
import React, { FC, useCallback } from "react";
import { useQueryClient } from "react-query";
import Row from "../components/Row";
import { useAllDistinctModes, useCountMode, useDates } from "../hooks";
import { ModeType } from "../hooks/types";
import { translateMode } from "../utils";

const Modes: FC = () => {
  const { data: allDistinctModes } = useAllDistinctModes();
  const { dates } = useDates();
  const queryClient = useQueryClient();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);

  const sortModes = useCallback(
    (a: string, b: string) => {
      const count1 =
        queryClient.getQueryData<number>(["tracker-count-mode", from, to, a]) ||
        0;
      const count2 =
        queryClient.getQueryData<number>(["tracker-count-mode", from, to, b]) ||
        0;
      return count1 > count2 ? -1 : 1;
    },
    [from, to, queryClient]
  );

  return (
    <div className="flex-1 divide-y divide-gray-300">
      <h1>Modis</h1>
      <ul className="py-4">
        {allDistinctModes?.sort(sortModes).map((mode, idx) => (
          <Mode key={mode} {...{ mode, idx }} />
        ))}
      </ul>
    </div>
  );
};

interface ModeProps {
  mode: ModeType;
  idx: number;
}

const Mode: FC<ModeProps> = ({ mode, idx }) => {
  const { data: countMode } = useCountMode(mode);
  return (
    <Row
      label={translateMode(mode)}
      result={countMode || 0}
      variant={idx % 2 === 0 ? "primary" : "secondary"}
    />
  );
};

export default Modes;
