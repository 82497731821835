import { useContext } from "react";
import { DatesContext } from "../context";

export const useDates = () => {
  const context = useContext(DatesContext);
  if (!context) {
    throw new Error("missing Provider for Context");
  }
  return context;
};
