import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
} from "react-query";
import axios from "axios";
import { MET, TRACKER, URL } from "./constants";
import { PlatformType } from "./types";
import { useDates } from "./useDates";
import moment from "moment";

export const getAllDistinctApps = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: TRACKER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]}`,
    distinct: "DEVICE_ID",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useAllDistinctApps(options?: QueryObserverOptions<string[]>) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<string[]>({
    queryKey: ["tracker-all-distinct-apps", from, to],
    queryFn: getAllDistinctApps,
    ...options,
  });
}

export const getAllPlatformApps = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: TRACKER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]} and PLATFORM=${context.queryKey[3]}`,
    distinct: "DEVICE_ID",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useAllPlatformApps(
  platform: PlatformType,
  options?: QueryObserverOptions<string[]>
) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<string[]>({
    queryKey: ["tracker-all-platform-apps", from, to, platform],
    queryFn: getAllPlatformApps,
    ...options,
  });
}
