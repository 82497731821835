import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
} from "react-query";
import axios from "axios";
import { MET, SESSION, URL } from "./constants";
import { TrackerSessionType } from "./types";
import { useDates } from "./useDates";
import moment from "moment";

export const getAllSessions = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: SESSION,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]}`,
    attributes: "FREQUENCY,DURATION",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useAllSessions(
  options?: QueryObserverOptions<TrackerSessionType[]>
) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<TrackerSessionType[]>({
    queryKey: ["tracker-sessions-all", from, to],
    queryFn: getAllSessions,
    ...options,
  });
}
