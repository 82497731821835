import React, { FC } from "react";
import { Box, SharedBox } from "../components";
import {
  useAllClicks,
  useAllDistinctApps,
  useAllPlatformApps,
  useAllSessions,
  useUsers,
} from "../hooks";

const Dashboard: FC = () => {
  const { data: allApps } = useAllDistinctApps();
  const { data: allSessions } = useAllSessions();
  const { data: allIOSApps } = useAllPlatformApps("ios");
  const { data: allAndroidApps } = useAllPlatformApps("android");
  const { data: allWebApps } = useAllPlatformApps("web");
  const { data: allUsers } = useUsers();
  const { data: allClicks } = useAllClicks();

  const allAppsLength = allApps?.length || 0;
  const allSessionsLength = allSessions?.length || 0;
  const clicksPerSession = (allClicks || 0) / allSessionsLength;
  const durationPerSession =
    (allSessions?.reduce((acc, val) => acc + val.DURATION, 0) || 0) /
    allSessionsLength;

  const allIOSAppsLength = allIOSApps?.length || 0;
  const allAndroidAppsLength = allAndroidApps?.length || 0;
  const allWebAppsLength = allWebApps?.length || 0;

  const allUsersLength = allUsers?.length || 0;

  const allIOSUsersLength =
    allUsers?.reduce((acc, val) => acc + (val.PLATFORM === "ios" ? 1 : 0), 0) ||
    0;

  const allAndroidUsersLength =
    allUsers?.reduce(
      (acc, val) => acc + (val.PLATFORM === "android" ? 1 : 0),
      0
    ) || 0;

  const allIOSUsersPercentage = Math.round(
    (allIOSUsersLength / allUsersLength) * 100
  );
  const allAndroidUsersPercentage = Math.round(
    (allAndroidUsersLength / allUsersLength) * 100
  );

  return (
    <div className="flex flex-wrap">
      <Box label={"aktive Apps"} result={allAppsLength} variant={"primary"} />
      <SharedBox
        labels={["ios", "android", "web"]}
        results={[allIOSAppsLength, allAndroidAppsLength, allWebAppsLength]}
      />
      <Box label={"neue User"} result={allUsersLength} variant={"primary"} />
      <SharedBox
        labels={["ios", "android"]}
        results={[allIOSUsersLength, allAndroidUsersLength]}
        description={[allIOSUsersPercentage, allAndroidUsersPercentage]}
      />
      <Box label={"Sessions"} result={allSessionsLength} variant={"primary"} />
      <Box label={"Klicks"} result={allClicks || 0} variant={"secondary"} />
      <Box
        label={"Klicks/Session"}
        result={Math.round(clicksPerSession)}
        variant={"primary"}
      />
      <Box
        label={"Dauer/Session (sec)"}
        result={Math.round(durationPerSession)}
        variant={"secondary"}
      />
    </div>
  );
};

export default Dashboard;
