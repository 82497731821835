import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQueries,
  useQuery,
  UseQueryOptions,
} from "react-query";
import axios from "axios";
import { GUIDELINE, MET, TRACKER, URL } from "./constants";
import { GuidelineType } from "./types";
import { useDates } from "./useDates";
import moment from "moment";

export const getAllDistinctGuidelines = async (
  context: QueryFunctionContext
) => {
  const params = {
    met: MET,
    entity: TRACKER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]} and GUIDELINE_UUID#""`,
    distinct: "GUIDELINE_UUID",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useAllDistinctGuidelines(
  options?: QueryObserverOptions<string[]>
) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<string[]>({
    queryKey: ["tracker-all-distinct-guidelines", from, to],
    queryFn: getAllDistinctGuidelines,
    ...options,
  });
}

export const getGuideline = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: GUIDELINE,
    get: context.queryKey[1],
    attributes: "UUID,TITLE,TITLE_SHORT,STATUS",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useGuideline(
  uuid: string,
  options?: QueryObserverOptions<GuidelineType>
) {
  return useQuery<GuidelineType>({
    queryKey: ["guideline", uuid],
    queryFn: getGuideline,
    ...options,
  });
}

export const getCountGuideline = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: TRACKER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]} and GUIDELINE_UUID=${context.queryKey[3]}`,
    count: "GUIDELINE_UUID",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useCountGuideline(
  uuid: string,
  options?: QueryObserverOptions<number>
) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<number>({
    queryKey: ["tracker-count-guideline", from, to, uuid],
    queryFn: getCountGuideline,
    ...options,
  });
}
