import React, { useCallback, useState } from "react";
import { DatesProvider } from "./context";
import { DatePicker, GlobalLoadingIndicator, Header } from "./components";
import {
  Dashboard,
  Feedbacks,
  Guidelines,
  Modes,
  Disabled,
  Auth,
} from "./container";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocalStorage } from "./hooks";

const queryClient = new QueryClient();

function App() {
  const [isLoggedIn] = useLocalStorage("loggedIn", false);
  const [_, setRefresh] = useState(false);
  const onRefresh = useCallback(() => setRefresh((prev) => !prev), []);
  return (
    <QueryClientProvider client={queryClient}>
      {isLoggedIn ? (
        <main className="container mx-auto px-2">
          <GlobalLoadingIndicator />
          <DatesProvider>
            <div className="container mx-auto space-y-4">
              <Header />
              <div className="flex flex-col md:flex-row">
                <DatePicker />
                <Feedbacks />
              </div>
              <Dashboard />
              <Disabled />
              <button className="btn-primary pb-2" onClick={onRefresh}>
                sortieren
              </button>
              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <Guidelines />
                <Modes />
              </div>
            </div>
          </DatesProvider>
        </main>
      ) : (
        <Auth />
      )}
    </QueryClientProvider>
  );
}

export default App;
