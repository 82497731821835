import React, { FC } from "react";

interface Props {
  labels: string[];
  results: number[] | string[];
  description?: number[];
}
const SharedBox: FC<Props> = ({ labels, results, description }) => {
  return (
    <div className="w-44 h-44 flex rounded-md shadow-md bg-white border border-gray-200 p-2 mr-2 mb-2">
      <div className="flex-1 flex justify-evenly items-center">
        {labels.map((_, i) => (
          <div key={i}>
            <h4 className="text-center font-light">{labels[i]}</h4>
            <h2 className="text-center font-extrabold">
              {results[i]}
              <span className="font-light text-xs">
                {description && `[${description[i]}]`}
              </span>
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedBox;
