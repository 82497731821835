import React, { FC, SyntheticEvent } from "react";
import { useLocalStorage } from "../hooks";

const Auth: FC = () => {
  const [_, setLoggedIn] = useLocalStorage("loggedIn", false);
  const onSubmit = (event: SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    // @ts-expect-error
    const password = event.target.password.value;
    setLoggedIn(password === "#lindgruen");
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="p-10 bg-gray-100 border border-gray-200 rounded-xl shadow-md">
        <h1>Login</h1>
        <form className="py-2" onSubmit={onSubmit}>
          <input type="password" name="password" />
          <button type="submit" className="ml-1">
            senden
          </button>
        </form>
      </div>
    </div>
  );
};

export default Auth;
