import { ModeType } from "../hooks/types";

export const translateMode = (mode: ModeType) => {
  switch (mode) {
    case "score":
      return "Score";
    case "fullText":
      return "Volltext";
    case "recommendations":
      return "Empfehlungen";
    case "glossary":
      return "Glossarbeiträge";
    case "guideline":
      return "Horizontale Integration";
    case "reference":
      return "Quellen";
    case "stepByStep":
      return "Schritt für Schritt";
    case "information":
      return "Angaben zur Leitlinie";
    case "search":
      return "Suchen";
    case "extendedSearch":
      return "erweiterte Suche (alt)";
    default:
      return "keine Angaben";
  }
};
