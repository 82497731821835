import React, { FC, HTMLAttributes } from "react";
import cn from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  variant: "primary" | "secondary" | "error";
  label: string;
  result: string | number;
}
const Row: FC<Props> = ({ label, result, variant, className }) => {
  const rootClassName = cn(
    "flex items-center rounded-md shadow-md p-2 mb-2 border border-gray-200",
    {
      "bg-gray-100": variant === "primary",
      "bg-white": variant === "secondary",
      "bg-red-400": variant === "error",
    },
    className
  );
  return (
    <div className={rootClassName}>
      <h2 className="font-extrabold">{result}</h2>
      <h4 className="pl-2 font-light">{label}</h4>
    </div>
  );
};

export default Row;
