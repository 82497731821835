import {
  QueryFunctionContext,
  QueryObserverOptions,
  useMutation,
  useQuery,
} from "react-query";
import axios from "axios";
import { MET, POST_REQUEST, URL, USER } from "./constants";
import { useDates } from "./useDates";
import moment from "moment";
import { UserType } from "./types";

export const getUsers = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: USER,
    query: `CREATION_DATE>=${context.queryKey[1]} and CREATION_DATE<=${context.queryKey[2]}`,
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useUsers(options?: QueryObserverOptions<UserType[]>) {
  const { dates } = useDates();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);
  return useQuery<UserType[]>({
    queryKey: ["tracker-all-distinct-user", from, to],
    queryFn: getUsers,
    ...options,
  });
}

export const getDisabledUsers = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    entity: USER,
    query: "DISABLE_TRACKER=true",
    orderBy: "CREATION_DATE desc",
    attributes: "ID",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useDisabledUsers(options?: QueryObserverOptions<UserType[]>) {
  return useQuery<UserType[]>({
    queryKey: ["tracker-all-disabled-user"],
    queryFn: getDisabledUsers,
    ...options,
  });
}

export const mutateUser = async (user: UserType) => {
  const params = {
    met: MET,
    entity: USER,
    request: POST_REQUEST,
    get: user.ID,
    DISABLE_TRACKER: user.DISABLE_TRACKER,
    attributes: "ID,DISABLE_TRACKER",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useUserMutation(options?: QueryObserverOptions<UserType>) {
  //@ts-expect-error
  return useMutation<UserType>(mutateUser, options);
}
