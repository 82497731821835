import React, { FC } from "react";
import { useIsFetching } from "react-query";
import cn from "classnames";

const GlobalLoadingIndicator: FC = () => {
  const isFetching = useIsFetching();
  const rootClassName = cn("h-1 bg-red-400 transition-width", {
    "w-full": isFetching,
    "w-0": !isFetching,
  });
  return <div className={rootClassName} />;
};

export default GlobalLoadingIndicator;
