import React, { FC } from "react";
import Row from "../components/Row";
import { useFeedbacks } from "../hooks";

const Feedbacks: FC = () => {
  const { data: feedbacks } = useFeedbacks();
  return (
    <div className="flex-1 divide-y divide-gray-300">
      <h1>Feedback</h1>
      <ul className="py-4">
        {feedbacks
          ?.sort((a, b) => (a.CREATION_DATE > b.CREATION_DATE ? -1 : 1))
          .map((feedback, idx) => (
            <Row
              label={feedback.MESSAGE}
              result={feedback.CREATION_DATE}
              variant={idx % 2 === 0 ? "primary" : "secondary"}
            />
          ))}
      </ul>
    </div>
  );
};

export default Feedbacks;
