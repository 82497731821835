import moment from "moment";
import React, { FC, useCallback } from "react";
import { useQueryClient } from "react-query";
import Row from "../components/Row";
import {
  useGuideline,
  useAllDistinctGuidelines,
  useCountGuideline,
  useDates,
} from "../hooks";

const Guidelines: FC = () => {
  const { data: allDistinctGuidelines } = useAllDistinctGuidelines();
  const { dates } = useDates();
  const queryClient = useQueryClient();
  const from = moment(dates.from).format(moment.HTML5_FMT.DATE);
  const to = moment(dates.to).format(moment.HTML5_FMT.DATE);

  const sortGuidelines = useCallback(
    (a: string, b: string) => {
      const count1 =
        queryClient.getQueryData<number>([
          "tracker-count-guideline",
          from,
          to,
          a,
        ]) || 0;
      const count2 =
        queryClient.getQueryData<number>([
          "tracker-count-guideline",
          from,
          to,
          b,
        ]) || 0;
      return count1 > count2 ? -1 : 1;
    },
    [from, to, queryClient]
  );

  return (
    <div className="flex-1 divide-y divide-gray-300">
      <h1>Leitlinien</h1>
      <ul className="py-4">
        {allDistinctGuidelines?.sort(sortGuidelines).map((uuid, idx) => (
          <Guideline key={uuid} {...{ uuid, idx }} />
        ))}
      </ul>
    </div>
  );
};

interface GuidelineProps {
  uuid: string;
  idx: number;
}

const Guideline: FC<GuidelineProps> = ({ uuid, idx }) => {
  const { data: guideline } = useGuideline(uuid);
  const { data: countGuideline } = useCountGuideline(uuid);
  return (
    <Row
      label={guideline?.TITLE_SHORT || ""}
      result={countGuideline || 0}
      variant={
        guideline?.STATUS === "ABGELAUFEN"
          ? "error"
          : idx % 2 === 0
          ? "primary"
          : "secondary"
      }
    />
  );
};

export default Guidelines;
